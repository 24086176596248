import { DashboardPage } from "../lib/features/auth/DashboardPage";
import { useGetProfileQuery } from "@/lib/services/user";
import MySpace from "./my-space";
import ManagementDashboard from "./management";

const Home: DashboardPage = () => {
  const profileResult = useGetProfileQuery();
  const isSuperUser = profileResult.data?.user.is_superuser;

  if (isSuperUser) {
    return <ManagementDashboard />;
  }

  return <MySpace />;
};

Home.auth = true;
export default Home;
